/* src/components/ContactUs.css */
.contact-us-section {
  background-color: #535b60;
  color: #FEFEFE;
  padding: 10px 20px;
  margin: 0 auto;
  padding-top: 20px;
  padding-bottom: 10px;
}

.contact-us-content {
  opacity: 0;
  transform: translateY(50px); /* Start position below the original */
  transition: opacity 1s ease-in-out, transform 1s ease-in-out; /* Combined transition for opacity and transform */
}

.contact-us-content.fade-in {
  opacity: 1;
  transform: translateY(0); /* Final position */
}

@media (min-width: 750px) {
  .contact-us-section {
    padding: 10px 15%;
  }
}

.contact-us-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.contact-us-icon {
  margin-right: 8px;
  color: #798A92;
}

.contact-us-title {
  color: #FEFEFE;
  font-size: 0.875rem;
}

.contact-us-main-title {
  text-align: left;
  font-weight: 500;
  margin-bottom: 20px;
  padding-bottom: 40px;
}

.contact-us-description {
  text-align: left;
  margin-bottom: 40px;
  padding-bottom: 40px;
}

.contact-us-button {
  background-color: #798A92 !important;
  color: #FEFEFE;
  text-transform: none;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact-us-button:hover {
  background-color: #627480;
}

.contact-us-image-container {
  display: flex;
  justify-content: flex-end;
}

.contact-us-image {
  width: 100%;
  max-width: 300px;
  height: auto;
  border-radius: 8px;
  object-fit: contain;
}
