/* src/components/AboutUsPage/Team.css */
.team-container {
  padding: 40px 15%;
  background-color: #535b60;
  color: white;
}

/* src/components/CompanyProfile.css */
.team-content {
  opacity: 0;
  transform: translateY(50px); /* Start position below the original */
  transition: opacity 1s ease-in-out, transform 1s ease-in-out; /* Combined transition for opacity and transform */
}

.team-content.fade-in {
  opacity: 1;
  transform: translateY(0); /* Final position */
}


.team-title {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 40px;
  position: relative;
  text-align: left;
  padding-bottom: 40px;
}

.team-title::before {
  content: '';
  position: absolute;
  left: -20px;
  top: 0;
  bottom: 0;
  width: 5px;
  height: calc(100% - 40px);
  background-color: #798A92;
}

.team-member {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border-radius: 10px;
  margin-bottom: 30px;
  color: #333;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.team-photo {
  padding: 20px;
  width: 20vh !important;
  height: 20vh;
  object-fit: cover;
  border-radius: 50%;
  margin-left: 20px;
}

.team-info {
  padding-left: 20px;
}

.team-name {
  font-size: 24px;
  font-weight: bold;
}

.team-position {
  font-size: 18px;
  color: #798A92;
  padding-bottom: 10px;
}

.team-bio {
  font-size: 16px;
  margin-top: 10px;
}

@media (max-width: 768px) {
  .team-member {
    flex-direction: column;
    text-align: center;
    align-items: center;
  }

  .team-photo {
    margin: 20px auto 10px;
    width: 60vw !important; /* Smaller image size on mobile */
    height: 60vw;
  }

  .team-info {
    padding-left: 0;
    padding-top: 10px;
  }

  .team-name {
    margin-top: 10px;
  }

  .team-position {
    padding-bottom: 0;
  }
}
