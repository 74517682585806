/* General styling */
.projects-page {
  padding: 20px;
  padding-top: 40px;
}

.projects-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding: 10px 20px;
  margin: 0 auto;
  padding-top: 10px;
}

.projects-title {
  text-align: center; /* Center the title */
  font-size: 32px;
  font-weight: bold;
  position: relative;
  padding-left: 25px; /* Add padding for the title strip */
}

.projects-title::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 5px;
  height: calc(100%);
  background-color: #798A92;
}

.view-toggle-buttons {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.view-toggle-buttons > button {
  margin-left: 10px;
}

.projects-content {
  padding: 10px 20px;
  margin: 0 auto;
  padding-top: 10px;
  padding-bottom: 10px;
}

.projects-section-title {
  text-align: center; /* Center the section titles */
  font-size: 24px;
  font-weight: bold;
  position: relative;
  padding-left: 25px; /* Add padding for the title strip */
  margin-bottom: 20px; /* Add space below the title */
}

@media (max-width: 719px) {
  .projects-header {
    flex-direction: column; /* Stack the elements vertically */
    align-items: center; /* Center the elements */
  }

  .view-toggle-buttons {
    margin-top: 10px; /* Add some space above the buttons */
    justify-content: center; /* Center the buttons */
  }

  .view-toggle-buttons > button {
    margin-left: 0; /* Remove left margin */
    margin-bottom: 10px; /* Add space between buttons */
    width: 100%; /* Make buttons full width */
  }
}

@media (min-width: 750px) {
  .projects-content, .projects-header {
    padding: 10px 5%;
  }
}
