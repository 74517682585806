/* src/components/ProjectPage/ProjectPage.css */
.project-page {
  width: 100%;
  overflow: hidden;
}

.project-page-banner {
  position: relative;
  width: 100%;
  height: 60vh; /* Adjust the height as necessary */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: flex-end;
}

.project-page-banner-content {
  width: 100%;
  color: #FEFEFE;
  text-align: left;
  padding: 20px 5%;
  box-sizing: border-box;
}

.project-page-title-container {
  position: relative;
  margin-bottom: 10px;
}

.project-page-title {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 10px;
  position: relative;
  padding-left: 25px; /* Adjust padding for the title strip */
}

.project-page-title::before {
  content: '';
  position: absolute;
  left: 0; /* Starting position of the strip */
  top: 0;
  bottom: 0;
  width: 5px;
  height: calc(100%);
  background-color: #798A92;
}

.project-page-subtitle {
  font-size: 18px;
  margin-bottom: 5px;
  color: #fefefec9;
  padding-left: 25px
}

.project-page-content {
  padding: 10px 15%;
  background-color: #ffffff;
}

.project-page-description {
  font-size: 16px;
  line-height: 1.6;
  color: #333;
}
