.hero-section {
  position: relative;
  height: 85vh;
  overflow: hidden;
}

@media (min-width: 650px) {
  .hero-section {
    height: 95vh;
  }
}

.hero-section-image {
  width: 100%;
  height: 95vh;
  object-fit: cover;
}

.hero-section-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.7), transparent);
  display: flex;
  align-items: center;
  z-index: 1;
}

.hero-section-text-container {
  position: absolute;
  bottom: 10%;
  left: 5%;
  width: 90%;
  z-index: 2;
}

.hero-section-content {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;
  color: white;
  position: relative;
}

.hero-section-title {
  font-weight: bold;
  font-size: 40px !important;
  line-height: 1.2;
  padding-left: 25px;
  width: 100%;
  display: flex;
  align-items: center;
}

.hero-section-title-strip {
  position: absolute;
  background-color: #798A92;
  width: 5px;
  height: 100%;
  margin-right: 10px;
  left: 0;
}

@media (max-width: 850px) {
  .hero-section-title {
    font-size: 30px !important;
  }
}

@media (max-width: 450px) {
  .hero-section-title {
    font-size: 20px !important;
  }
}
