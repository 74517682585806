/* src/components/ResourcesPage/ResourcesGrid.css */
.resources-grid {
  position: relative;
  max-width: 100%;
  margin: 0 auto;
  color: #333;
  padding-bottom: 20px;
}

.resources-grid-content {
  background-color: #fff;
  padding: 20px 10vw;
  border-radius: 8px;
  margin: -60px auto 0;
  max-width: calc(100% - 100px);
  opacity: 0;
  transform: translateY(50px); /* Start position below the original */
  transition: opacity 1s ease-in-out, transform 1s ease-in-out; /* Combined transition for opacity and transform */
}

@media (min-width: 750px) {
  .resources-grid-content  {
    padding: 20px 1vw;
  }
}


.resources-grid-content.fade-in {
  opacity: 1;
  transform: translateY(0); /* Final position */
}

.resources-grid-title {
  text-align: left;
  font-size: 32px;
  font-weight: bold;
  padding-bottom: 20px;
  position: relative;
}

.resources-grid-title::before {
  content: '';
  position: absolute;
  left: -20px;
  top: 0;
  bottom: 0;
  width: 5px;
  height: calc(100% - 20px);
  background-color: #798A92;
}

.resources-grid-item {
  display: flex;
  justify-content: center;
}

.resources-grid-icon-container {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.resources-grid-icon-circle {
  width: 100px;
  height: 100px;
  border: 2px solid #798A92;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 10px auto;
}

.resources-grid-icon {
  color: #798A92;
}

.resources-grid-content-title {
  font-size: 18px;
  color: #333;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
