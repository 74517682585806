/* src/components/AboutUsPage/WorkWithUs.css */
.work-with-us-container {
    padding: 40px 15%;
    background-color: #535b60; /* Dark background matching Team section */
    color: #ffffff; /* White text */
}

.work-with-us-section {
    opacity: 0;
    transform: translateY(50px); /* Start position below the original */
    transition: opacity 1s ease-in-out, transform 1s ease-in-out; /* Combined transition for opacity and transform */
  }
  
  .work-with-us-section.fade-in {
    opacity: 1;
    transform: translateY(0); /* Final position */
  }

.work-with-us-title {
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 40px;
    position: relative;
    text-align: left;
    padding-bottom: 40px;
}

.work-with-us-title::before {
    content: '';
    position: absolute;
    left: -20px;
    top: 0;
    bottom: 0;
    width: 5px;
    height: calc(100% - 40px);
    background-color: #798A92; /* Same color as Team section */
}

.work-with-us-content {
    font-size: 16px;
    margin-bottom: 20px;
    line-height: 1.6;
    padding-bottom: 40px;
}

.work-with-us-button {
    background-color: #798A92 !important;
    color: #FEFEFE;
    text-transform: none; /* Ensure text is not all uppercase */
    padding: 8px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.work-with-us-button:hover {
    background-color: #627480; /* Slightly darker on hover */
}