/* src/components/ProjectsPage/ProjectsMap.css */
.map-container {
  position: relative;
  width: 100%;
  height: 550px;
  display: flex;
  overflow: hidden;
  opacity: 0;
  transform: translateY(50px); /* Start position below the original */
  transition: opacity 1s ease-in-out, transform 1s ease-in-out; /* Combined transition for opacity and transform */
}

@media (min-width: 650px) {
  .map-container {
    height: 700px;
  }
}

.map-container.fade-in {
  opacity: 1;
  transform: translateY(0); /* Final position */
}

.projects-map {
  height: 100%;
  width: 100%;
  flex-grow: 1;
}

.custom-div-icon {
  position: relative;
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.marker-pin {
  width: 118px;
  height: 70px;
  border-radius: 10%;
}

.marker-pin.project {
  border: 4px solid #798A92;
}

.marker-pin.experience {
  border: 4px solid darkgreen;
}

.marker-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10%;
}

.drawer-content {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  max-width: 600px;
  width: 50%;
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
  z-index: 1000; /* Ensure the drawer is above the map */
  background-color: white; /* Add background to cover the map */
}

/* Mobile (up to 1023px) */
@media (max-width: 599px) {
  .drawer-content {
    width: 80%;
  }
}


.drawer-content.open {
  transform: translateX(0);
}

.project-drawer {
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;
}

.project-drawer-image {
  width: 100%;
  height: 0;
  padding-top: 56.25%; /* 16:9 aspect ratio */
  background-size: cover;
  background-position: center;
  position: relative;
}

.project-drawer-content {
  padding: 20px;
  padding-left: 40px;
  flex: 1;
  overflow-y: auto; /* Allow content to scroll if necessary */
  position: relative;
}

.project-drawer .MuiTypography-h5 {
  position: relative;
  padding-bottom: 10px;
}

.project-drawer .MuiTypography-subtitle1 {
  position: relative;
  margin-bottom: 20px;
}

.project-drawer .MuiTypography-body1 {
  padding-bottom: 20px;
}

.close-button {
  position: absolute !important;
  top: 10px;
  right: 10px;
  z-index: 1;
  background-color: white !important;
}

.project-map-drawer-button {
  justify-content: center;
  padding-top: 20px;
}

/* Title strip before heading */
.project-drawer .MuiTypography-h5::before,
.project-drawer .MuiTypography-subtitle1::before {
  content: '';
  position: absolute;
  left: -20px;
  top: 0;
  bottom: 0;
  width: 5px;
  background-color: #798A92; /* Default color */
}

.project-drawer.project .MuiTypography-h5::before,
.project-drawer.project .MuiTypography-subtitle1::before {
  background-color: #798A92; /* Project color */
}

.project-drawer.experience .MuiTypography-h5::before,
.project-drawer.experience .MuiTypography-subtitle1::before {
  background-color: darkgreen; /* Experience color */
}

/* Adjustments for specific content types */
.project-drawer.project .project-drawer-content {
  background-color: #535b60;
  color: white;
}

.project-drawer.experience .project-drawer-content {
  background-color: #f0f7f1;
  color: black;
}
