.services-grid {
  position: relative;
  max-width: 100%;
  margin: 0 auto;
  color: #333;
  padding-bottom: 20px;
}

.services-grid-content {
  background-color: #fff;
  padding: 20px 10vw;
  border-radius: 8px;
  margin: -60px auto 0;
  max-width: calc(100% - 20px);
  
    opacity: 0;
    transform: translateY(50px); /* Start position below the original */
    transition: opacity 1s ease-in-out, transform 1s ease-in-out; /* Combined transition for opacity and transform */
}

.services-grid-content.fade-in {
  opacity: 1;
  transform: translateY(0); /* Final position */
}

.services-grid-title {
  text-align: left;
  font-size: 32px;
  font-weight: bold;
  padding-bottom: 40px;
  position: relative;
}

.services-grid-title::before {
  content: '';
  position: absolute;
  left: -20px;
  top: 0;
  bottom: 0;
  width: 5px;
  height: calc(100% - 40px);
  background-color: #798A92;
}

.services-grid-item {
  display: flex;
  justify-content: center;
}

.services-grid-card {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3) !important;
  text-align: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 320px;
}

.services-grid-image-container {
  width: 100%;
  height: 180px;
  overflow: hidden;
  border-radius: 10px 10px 0 0;
}

.services-grid-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.services-grid-content-box {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
}

.services-grid-content-title {
  font-size: 18px;
  color: #333;
  min-height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-bottom: 20px;
}

.services-grid-button {
  margin-top: auto;
  width: 50%;
  align-self: center;
}

.services-grid-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 800px;
  overflow: hidden;
}

.services-grid-modal-banner {
  position: relative;
  width: 100%;
  height: 200px;
  overflow: hidden;
  background-color: #f0f0f0;
}

.services-grid-modal-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.services-grid-modal-close {
  width: 100%;
  justify-content: end !important;
  color: #000;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 50%;
  z-index: 2;
}

.services-grid-modal-content {
  padding: 30px;
}

.services-grid-modal-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  position: relative;
}


.services-grid-modal-description {
  font-size: 16px;
  line-height: 1.6;
}