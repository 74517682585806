/* src/pages/Contact.css */
.contact-page {
    padding: 10px 20px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
}

.contact-page-title {
    padding-bottom: 10px;
}

.contact-page-description {
    padding-bottom: 20px;
}

.contact-page-form {
    width: 100%;
}

.contact-page-button {
    margin-top: 20px;
}

.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

@media (min-width: 750px) {
    .contact-page {
        padding: 10px 15%;
    }
}
