/* src/components/ContactPage/ContactInfo.css */
.contact-info-wrapper {
    width: 100vw;
    background-color: #535b60; /* Similar color scheme */
    padding: 40px 0; /* Padding on top and bottom */
  }
  
  .contact-info-container {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    padding: 10px 20px; /* Padding on left and right */

  }

  
  @media (min-width: 750px) {
    .contact-info-container {
      padding: 10px 15%;
    }
  }
  
  
  .contact-section, .media-enquiries-section {
    width: 45%;
    color: #fff; /* Text color to white for better contrast */
  }
  
  .contact-info-title {
    font-size: 24px;
    font-weight: bold;
    position: relative;
    padding-bottom: 20px; /* Padding below the title */
    margin-bottom: 20px; /* Adding space between the title and the underline */
  }
  
  .contact-info-title::after {
    content: '';
    display: block;
    width: 15vh; /* Adjust the width as needed */
    height: 3px; /* Adjust the height as needed */
    background-color: #798A92; /* Color for the underline */
    position: absolute;
    bottom: 10px; /* Align the underline at the bottom of the padding */
    left: 0;
  }
  
  .contact-info-item {
    margin-top: 25px; /* Space below the underline and text */
  }
  
  @media (max-width: 768px) {
    .contact-info-container {
      flex-direction: column;
      align-items: center;
    }
  
    .contact-section, .media-enquiries-section {
      width: 100%;
      margin-bottom: 20px;
    }
  }
  