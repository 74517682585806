/* src/components/ServiceSection.css */
.service-section {
  padding: 10px 20px;
  background-color: #535b60; /* Background color matching the provided image */
  color: #FEFEFE; /* White text color */
  margin: 0 auto;
  padding-top: 10px;
  padding-bottom: 10px;
}

/* src/components/ServiceSection.css */
.service-section-content {
  opacity: 0;
  transform: translateY(50px); /* Start position below the original */
  transition: opacity 1s ease-in-out, transform 1s ease-in-out; /* Combined transition for opacity and transform */
}

.service-section-content.fade-in {
  opacity: 1;
  transform: translateY(0); /* Final position */
}



@media (min-width: 750px) {
  .service-section {
    padding: 10px 15%;
  }
}

.service-section-header {
  padding: 10px 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  min-height: 50px;
}

.service-section-header-icon {
  margin-right: 8px;
  color: #FEFEFE;
}

.service-section-header-title {
  color: #FEFEFE;
}

.service-section-title {
  padding: 10px 20%;
  text-align: center;
  font-weight: 500;
  margin-bottom: 20px;
  min-height: 50px;
  margin: 0 auto;
  padding-top: 10px;
  padding-bottom: 10px;
}

.service-section-description {
  padding: 10px 20%;
  text-align: center;
  margin-bottom: 40px;
  margin: 0 auto;
  padding-top: 10px;
  padding-bottom: 10px;
}

.service-section-grid {
  margin-bottom: 40px;
  display: flex;
  align-items: flex-start;
}

.service-section-card-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 0 30%; /* Ensure each card takes up about 30% of the row, adjust as needed */
  margin: 10px; /* Add margin for consistent spacing */
  box-sizing: border-box;
}

.service-section-card {
  background-color: transparent !important; /* No background color */
  box-shadow: none !important; /* Remove box shadow */
  color: #FEFEFE; /* White text color */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.service-section-card-image {
  width: 100%;
  height: 250px; /* Ensure consistent height */
  object-fit: cover; /* Ensure the image covers the entire area */
}

.service-section-card-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  padding: 10px 0;
}

.service-section-card-title {
  margin-bottom: 10px;
  color: #FEFEFE; /* White text color */
  min-height: 60px; /* Ensure consistent title height */
}

.service-section-card-description {
  margin-bottom: 20px;
  color: #FEFEFE; /* White text color */
  min-height: 80px; /* Ensure consistent description height */
}

/* Large desktops (1440px and above) */
@media (min-width: 1084px) {
  .service-section-card-title {
    min-height: 100px;
  }

  .service-section-card-description {
    min-height: 120px;
  }
}

/* Medium desktops (1024px to 1439px) */
@media (min-width: 600px) and (max-width: 1083px) {
  .service-section-card-title {
    min-height: 100px;
  }

  .service-section-card-description {
    min-height: 195px;
  }
}

/* Mobile (up to 1023px) */
@media (max-width: 599px) {
  .service-section-card-title {
    min-height: 40px;
  }

  .service-section-card-description {
    min-height: 60px;
  }
}

.service-section-learn-more-button {
  align-self: flex-start;
  background-color: transparent !important;
  color: #FEFEFE;
  border: 1px solid #535b60 !important;
  text-transform: none; /* Ensure text is not all uppercase */
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: none !important;
  position: relative; /* Required for positioning the pseudo-element */
}

.service-section-learn-more-button::after {
  content: '→';
  margin-left: 8px;
  font-size: 24px;
  background: #535b60; /* Same background as the button */
  border: 2px solid #FEFEFE;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1; /* Ensures the text is centered vertically */
}

.service-section-learn-more-button:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.service-section-footer {
  text-align: center;
}

.service-section-stat {
  background-color: #798A92;
  padding: 20px;
  border-radius: 8px;
  color: #FEFEFE;
  text-align: center;
}
