/* src/components/AboutUsPage/AboutUs.css */
.about-us-page {
    position: relative;
    overflow: hidden;
}

.about-us-page::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 70vh; /* Adjust as needed */
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
    pointer-events: none;
}

.about-us-banner {
    position: relative;
    height: 70vh; /* Adjust the height as needed */
    overflow: hidden;
}

.about-us-banner-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center -20vh; /* Shifts the image up */
    position: absolute;
    top: 0;
    left: 0;
}

@media (max-width: 850px) {
    .banner-image {
        object-position: center -5vh; /* Shifts the image up */
    }
}

.about-us-content {
    position: relative;
    margin-top: -25vh; /* Negative margin to pull up content over the image */
    z-index: 1; /* Ensure the content is above the image */
}
