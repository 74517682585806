.navbar {
  transition: background-color 0.3s ease, color 0.3s ease;
  background-color: #FEFEFE;
}

.navbar-logo {
  height: 65px;
  vertical-align: middle;
  margin-right: 16px;
}

@media (min-width: 650px) {
  .navbar-logo {
    height: 50px;
  }
}

.navbar-button {
  text-transform: none;
  font-size: 16px;
  padding: 8px 16px;
  color: #242424 !important;
}

.navbar-button.active {
  border-bottom: 2px solid #798A92 !important;
}

.drawer-logo-container {
  padding: 10px;
  background: #485A64;
}

.drawer-logo {
  width: 100%;
  padding: 10px;
  color: #242424 !important;
}

.drawer-container {
  text-align: center;
  background-color: #f5f5f5;
}

.drawer-paper {
  box-sizing: border-box;
  width: 240px;
  background-color: #f5f5f5;
  color: #242424;
}

.language-active-dot {
  color: #485A64;
  margin-left: 8px;
}

.MuiMenuItem-root {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.MuiPaper-root.MuiMenu-paper {
  background-color: #f5f5f5;
  border: 1px solid #e0e0e0;
}

.language-button {
  min-width: 120px;
  background-color: #ededed;
  color: #242424 !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
