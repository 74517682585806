/* src/components/AboutUsPage/CompanyProfile.css */
.company-profile-container {
  position: relative;
  max-width: 100%;
  margin: 0 auto;
  color: #333;
  padding-bottom: 20px;
}

/* src/components/CompanyProfile.css */
.company-profile-content {
  background-color: #fff;
  padding: 20px 10vw;
  border-radius: 8px;
  margin: -60px auto 0;
  max-width: calc(100% - 100px);
  opacity: 0;
  transform: translateY(50px); /* Start position below the original */
  transition: opacity 1s ease-in-out, transform 1s ease-in-out; /* Combined transition for opacity and transform */
}

.company-profile-content.fade-in {
  opacity: 1;
  transform: translateY(0); /* Final position */
}


.company-profile-title {
  text-align: left;
  font-size: 32px;
  font-weight: bold;
  padding-bottom: 20px;
  position: relative;
}

.company-profile-title::before {
  content: '';
  position: absolute;
  left: -25px;
  top: 0;
  bottom: 0;
  width: 5px;
  height: calc(100% - 20px);
  background-color: #798A92;
}

.company-profile-section {
  margin-bottom: 20px;
}

.section-heading {
  padding-top: 20px;
  font-size: 24px;
  font-weight: bold;
  color: #798A92;
  padding-bottom: 20px;
}

.section-content {
  font-size: 16px;
  line-height: 1.6;
  text-align: left;
  margin-bottom: 20px;
}

.image-container {
  position: relative;
  height: 400px; /* Increased height for the image */
  overflow: hidden;
  width: 100%; /* Ensures full-width for the image */
}

.text-content {
  position: relative;
  bottom: -10%; /* Adjust this value to overlap from the bottom */
  background-color: rgba(255, 255, 255, 1); /* Optional: Background for readability */
  max-width: 100%;
  margin-top: -100px; /* Ensures the text is overlapping the image */
}

.flex-container {
  display: flex;
  align-items: center;
}

