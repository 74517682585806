/* src/components/RecentProjects.css */
.recent-projects-section {
  padding: 40px 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin: 0 auto;
  color: #798A92; 
  opacity: 0;
  transform: translateY(50px); /* Start position below the original */
  transition: opacity 1s ease-in-out, transform 1s ease-in-out; /* Combined transition for opacity and transform */
}

@media (min-width: 650px) {
  .recent-projects-section {
    padding: 40px 15%;
  }
}

.recent-projects-section.fade-in {
  opacity: 1;
  transform: translateY(0); /* Final position */
}


.recent-projects-header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
}

.recent-projects-icon {
  margin-right: 8px;
  color: #798A92;
}

.recent-projects-title {
  color: #798A92;
}

.recent-projects-main-header {
  margin-bottom: 40px;
}

.recent-projects-main-title {
  text-align: left;
  font-weight: 500;
  margin-bottom: 20px;
  color: #798A92;
}

.recent-projects-grid {
  margin-bottom: 40px;
  display: flex;
  align-items: flex-start;
}

.recent-projects-grid-item {
  position: relative;
  overflow: hidden;
}

.recent-projects-project {
  width: 100%;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  background-size: cover;
  background-position: center;
  position: relative;
  transition: all 0.3s ease;
}

.recent-projects-project:hover {
  filter: grayscale(100%);
}

.recent-projects-project-overlay {
  background-color: rgba(0, 0, 0, 0.6);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.recent-projects-project:hover .recent-projects-project-overlay {
  opacity: 1;
}

.recent-projects-project-title {
  color: white;
  font-size: 1.2em;
  text-align: center;
}

.recent-projects-explore-more-button {
  background-color: #798A92;
  color: #FEFEFE;
  text-transform: none;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: none !important;
}

.recent-projects-explore-more-button:hover {
  background-color: rgba(120, 138, 146, 0.8);
}
