.footer-section {
  background-color: #ededed; /* Background colour */
  color: #000000; /* Text colour */
  padding: 20px 5%;
}

.footer-grid {
  display: flex;
  align-items: center;
  flex-wrap: wrap; /* Allow wrapping of content */
}

.footer-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.footer-logo-column {
  align-items: flex-start;
}

.footer-logo {
  max-width: 300px; /* Adjust the size of the logo as needed */
}

.footer-contact-column {
  align-items: flex-start;
}

.footer-contact {
  margin-bottom: 10px;
  padding-right: 20px;
}

.footer-button-column {
  display: flex;
  justify-content: flex-end;
  align-items: center; /* Centre align vertically */
  width: 100%;
}

.footer-button {
  background-color: #485A64;
  color: #FEFEFE;
  text-transform: none; /* Ensure text is not all uppercase */
  padding: 8px 16px;
}

.footer-button:hover {
  background-color: #627480;
}

@media (max-width: 900px) {
  .footer-button-column {
    padding-top: 40px;
    justify-content: center;
  }
}

/* Stack contact info and button on smaller screens */
@media (max-width: 622px) {
  .footer-contact-column {
    align-items: center;
    text-align: center;
    display: flex;
    flex-direction: column !important;
  }
}

.footer-company-info {
  padding-top: 20px;
  text-align: center;
  color: #7D7D7D; /* Grey text colour */
  font-size: 10px !important; /* Adjust font size as needed */
}
