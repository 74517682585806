/* src/pages/Services.css */
.services-page {
  position: relative;
  overflow: hidden;
}

.services-banner {
  position: relative;
  height: 70vh; /* Adjust the height as needed */
  overflow: hidden;
  z-index: 1; /* Ensure the banner is below the gradient */
}

.services-banner-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center -20vh; /* Shifts the image up */
  position: absolute;
  top: 0;
  left: 0;
}

.services-gradient-overlay {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 70vh; /* Adjust as needed */
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
  pointer-events: none;
  z-index: 2; /* Ensure the gradient is above the banner but below the content */
}

.services-content {
  margin-top: -25vh; 
  z-index: 3; /* Ensure the content is above the image and gradient */
  position: relative;
  padding: 30px 20px;
}

@media (min-width: 1200px) {
  .services-content {
    padding: 10px 10%;
  }
}

