.values-section {
  padding: 20px 15%;
  padding-top: 40px; /* Adjusted top padding */
  margin: 0 auto;
  background-color: #f7f7f7;
  padding-bottom: 40px;
  opacity: 0;
  transform: translateY(50px); /* Start position below the original */
  transition: opacity 1s ease-in-out, transform 1s ease-in-out; /* Combined transition for opacity and transform */
}

.values-section.fade-in {
  opacity: 1;
  transform: translateY(0); /* Final position */
}

.values-title {
  text-align: left;
  font-size: 32px;
  font-weight: bold;
  padding-bottom: 40px;
  position: relative;
  margin-bottom: 20px;
}

.values-title::before {
  content: '';
  position: absolute;
  left: -25px;
  top: 0;
  bottom: 0;
  width: 5px;
  height: calc(100% - 40px);
  background-color: #798A92;
}

.values-icon-box {
  display: flex;
  justify-content: center; /* Align icons to the left */
  align-items: center;
  border: 2px solid #798A92;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  margin-bottom: 10px; /* Reduced margin-bottom */
}

.values-icon {
  font-size: 50px;
  color: #798A92;
}

.values-subtitle {
  font-weight: bold;
  margin-bottom: 5px; /* Reduced margin-bottom */
  min-height: 5vh !important;
}

.value-description {
  color: #333;
}
