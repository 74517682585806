.newsfeed-container {
    padding: 10px 20px;
    margin: 0 auto;
    padding-top: 10px;
    padding-bottom: 10px;
    overflow: hidden; /* Hide any overflowing content */
  }
  
  .newsfeed-title {
    position: relative;
    padding-bottom: 20px;
  }
  
  .newsfeed-title::before {
    content: '';
    position: absolute;
    left: -15px;
    top: 0;
    bottom: 0;
    width: 5px;
    height: calc(100% - 20px);
    background-color: #798A92; /* Title strip color */
  }
  
  .newsfeed-card {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3) !important;
  }
  
  .newsfeed-iframe {
    width: 100%;
    height: 265vh; /* Set the iframe to take the full viewport height */
    border: none;
  }
  
  @media (min-width: 750px) {
    .newsfeed-container {
      padding: 10px 5%;
    }
  }
  