/* Add custom styles */
body, html  {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
    margin: 0;
    display: flex;
    flex-direction: column;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  }
  
  /* Custom Navbar styles */
  .MuiAppBar-root {
    background-color: #FEFEFE !important;
    box-shadow: none !important;
  }
  
  /* Ensure the content appears below the Navbar */
  .content-wrapper {
    padding-top: 55px; /* Adjust this value based on the height of your Navbar */
  }
  
  
  #root {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  
  main {
    flex: 1;
  }
  