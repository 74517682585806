/* Initial state: element is below its final position and invisible */
.construction-guidance-section {
  padding: 10px 20px;
  margin: 0 auto;
  padding-top: 10px;
  padding-bottom: 10px;
  opacity: 0;
  transform: translateY(50px); /* Start position (50px below the original position) */
  transition: opacity 1s ease-in-out, transform 1s ease-in-out;
}

/* Final state: element is in its final position and fully visible */
.construction-guidance-section.fade-in {
  opacity: 1;
  transform: translateY(0); /* Final position */
}


@media (min-width: 750px) {
  .construction-guidance-section {
    padding: 10px 15%;
  }
}

.construction-guidance-why-choose-us {
  display: flex;
  align-items: center;
}

.construction-guidance-why-choose-us-icon {
  margin-right: 8px;
  color: #798A92;
}

.construction-guidance-why-choose-us-text {
  color: #798A92;
}

.construction-guidance-main-title {
  font-weight: 500;
  color: #798A92;
}

.construction-guidance-icon-box {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #798A92;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  margin-bottom: 10px;
}

.construction-guidance-icon {
  font-size: 50px;
  color: #798A92;
}

.construction-guidance-subtitle {
  margin-bottom: 20px;
  min-height: 10vh; /* Ensure consistent height */
}

@media (min-width: 1400px) {
  .construction-guidance-subtitle {
    min-height: 5.5vh; /* Ensure consistent height */
  }
}

@media (min-width: 1084px) {
  .construction-guidance-subtitle {
    min-height: 7.5vh; /* Ensure consistent height */
  }
}

/* Medium desktops (1024px to 1439px) */
@media (min-width: 600px) and (max-width: 1083px) {
  .construction-guidance-subtitle {
    min-height: 10vh; /* Ensure consistent height */
  }
}

/* Mobile (up to 1023px) */
@media (max-width: 599px) {
  .construction-guidance-subtitle {
    min-height: 5.5vh; /* Ensure consistent height */
  }
}

.construction-guidance-description {
  margin-top: 0;
  padding-top: 10px; /* Added padding to prevent overlap */
}
